import styled from 'styled-components'
import { navigate } from 'gatsby'
import * as React from 'react';
import Layout from '@src/components/Layout';
import Chart from '@src/components/Chart';
import RefDataDate from '@src/components/RefDataDate';

// import './index.css';
import { Row, Col, Divider, Button } from 'antd';
import Filter from '@src/components/Filter';

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Banner from '@src/components/Banner';
import image_test01 from '/images/student_statistics_banner.png'
import ModalQinfo from '@src/components/modal/ModalQinfo';
import {
  InfoCircleOutlined,
  TableOutlined
} from '@ant-design/icons';
import bg from '/images/bg.png'
import students from '/images/students.png'
import student_woman from '/images/student_woman.png'
import student_man from '/images/student_man.png'
import ModalTabelByProvicce from '@src/components/modal/ModalTabelByProvicce';
import ModalTabelBySungkud from '@src/components/modal/ModalTabelBySungkud';
import confixColumnsTable from '@src/confixTable';
import api from '../../util/api';

var categories = [
  'อ.1',
  'อ.2',
  'อ.3',
  'ป.1',
  'ป.2',
  'ป.3',
  'ป.4',
  'ป.5',
  'ป.6',
  'ม.1',
  'ม.2',
  'ม.3',
  'ม.4',
  'ม.5',
  'ม.6',
  'ปวช.',
  'ปวส.',
  'อุดมศึกษา'
];


const styleCard = {
  borderRadius: 12,
  padding: '12px',
  margin: '18px 12px 18px 12px',
  backgroundColor: '#fff',
  boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
  display: 'flex',
  flexDirection: 'column',
}

const Title = styled.div`
  color: #333;
  font-size: ${props => (props.fontSize ? props.fontSize : "1.20rem")}; 
  font-family: Kanitxt-align: center;
`
const checkValue = (value) => (value ? value : 0)
const mapData = (data, filter) => {
  console.log("filter", filter)
  const _filterData = (x) => {
    if (filter.view === 'sungkud') {
      if (filter.type === 'sungkud') return (x.sungkud === filter.sungkud)
      else if (filter.type === 'area') return (x.sungkud === filter.sungkud && x.area === filter.area)
      else return true
    } else {
      if (filter.type === 'province') return (x.province_name === filter.province_name)
      else if (filter.type === 'city') return (x.province_name === filter.province_name && x.city_name === filter.city_name)
      else if (filter.type === 'tumbon') return (x.province_name === filter.province_name && x.city_name === filter.city_name && x.tumbon_name === filter.tumbon_name)
      else return true
    }

  }

  let result = {
    students_total: 0,
    students_women: 0,
    students_men: 0,
    studentStatistics:
      [{
        name: '',
        color: '#fff',
        data: [
          // {
          //   name: '2561',
          //   color: '#6ee7b7',
          //   y: 0,
          // },
          // {
          //   name: '2562',
          //   color: '#6ee7b7',
          //   y: 0,
          // },
          {
            name: '2563',
            color: '#6ee7b7',
            y: 0,
          },
          {
            name: '2564',
            color: '#6ee7b7',
            y: 0,
          },
          {
            name: '2565',
            color: '#6ee7b7',
            y: 0,
          },
          {
            name: '2566',
            color: '#6ee7b7',
            y: 0,
          },
          {
            name: '2567',
            color: '#10b981',
            y: 0,
          }
        ]
      }]
    ,
    studentByRegin: [{
      name: 'เหนือ',
      color: '#2dd4bf',
      data: [0, 0, 0, 0, 0]
    },
    {
      name: 'ใต้',
      color: '#38bdf8',
      data: [0, 0, 0, 0, 0]
    },
    {
      name: 'กลาง',
      color: '#818cf8',
      data: [0, 0, 0, 0, 0]
    },
    {
      name: 'ตะวันออกเฉียงเหนือ',
      color: '#FFADF0',
      data: [0, 0, 0, 0, 0]
    }
    ],
    studentGender: [
      {
        name: 'เพศหญิง',
        color: '#FFADF0',
        data: [0, 0, 0, 0, 0]
      },
      {
        name: 'เพศชาย',
        color: '#5584AC',
        data: [0, 0, 0, 0, 0]
      }
    ],
    studentLevelEduByGender: [{
      name: 'ชาย',
      color: '#5584AC',
      data: [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
      ]
    }, {
      name: 'หญิง',
      color: '#FFADF0',
      data: [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
      ]
    }],
    studentLevelEdu: [
      {
        name: 'อนุบาล',
        color: '#a5f3fc',
        data: [0, 0, 0, 0, 0]
      },
      {
        name: 'ประถมศึกษา',
        color: '#c7d2fe',
        data: [0, 0, 0, 0, 0]
      },
      {
        name: 'มัธยมต้น',
        color: '#e9d5ff',
        data: [0, 0, 0, 0, 0]
      },
      {
        name: 'มัธยมปลาย/ปวช.',
        color: '#fbcfe8',
        data: [0, 0, 0, 0, 0]
      },
      {
        name: 'อุดมศึกษา/ปวส.',
        color: '#fecdd3',
        data: [0, 0, 0, 0, 0]
      },
    ],
  }
  data
    .filter(_filterData)
    .forEach(x => {
      const SUFFIX_YEAR_INDEX = {
        "2563": 0,
        "2564": 1,
        "2565": 2,
        "2566": 3,
        "2567": 4
      }

      Object.keys(SUFFIX_YEAR_INDEX).forEach(key_year => {
        let _index = SUFFIX_YEAR_INDEX[key_year]
        let students = (x[`studentWomen${key_year}`] + x[`studentMen${key_year}`])
        result.studentStatistics[0].data[_index].y += students

        result.studentByRegin[0].data[_index] += x.region_thai === 'เหนือ' ? students : 0
        result.studentByRegin[1].data[_index] += x.region_thai === 'ใต้' ? students : 0
        result.studentByRegin[2].data[_index] += x.region_thai === 'กลาง' ? students : 0
        result.studentByRegin[3].data[_index] += x.region_thai === 'ตะวันออกเฉียงเหนือ' ? students : 0

        result.studentGender[0].data[_index] += x[`studentWomen${key_year}`]
        result.studentGender[1].data[_index] += x[`studentMen${key_year}`]

        result.studentLevelEdu[0].data[_index] += x[`k_student${key_year}`]
        result.studentLevelEdu[1].data[_index] += x[`p_student${key_year}`]
        result.studentLevelEdu[2].data[_index] += x[`mt_student${key_year}`]
        result.studentLevelEdu[3].data[_index] += x[`high_student${key_year}`]
        result.studentLevelEdu[4].data[_index] += x[`pvc_student${key_year}`]
        // result.studentLevelEdu[5].data[_index] += x[`pvs_student${key_year}`]
      })

      let year = filter.year
      result.students_total += (x[`studentWomen${year}`] + x[`studentMen${year}`])
      result.students_women += x[`studentWomen${year}`]
      result.students_men += x[`studentMen${year}`]

      // Men
      result.studentLevelEduByGender[0].data[0] -= x[`k1_m${year}`] ? x[`k1_m${year}`] : 0
      result.studentLevelEduByGender[0].data[1] -= x[`k2_m${year}`] ? x[`k2_m${year}`] : 0
      result.studentLevelEduByGender[0].data[2] -= x[`k3_m${year}`] ? x[`k3_m${year}`] : 0

      result.studentLevelEduByGender[0].data[3] -= x[`p1_m${year}`] ? x[`p1_m${year}`] : 0
      result.studentLevelEduByGender[0].data[4] -= x[`p2_m${year}`] ? x[`p2_m${year}`] : 0
      result.studentLevelEduByGender[0].data[5] -= x[`p3_m${year}`] ? x[`p3_m${year}`] : 0
      result.studentLevelEduByGender[0].data[6] -= x[`p4_m${year}`] ? x[`p4_m${year}`] : 0
      result.studentLevelEduByGender[0].data[7] -= x[`p5_m${year}`] ? x[`p5_m${year}`] : 0
      result.studentLevelEduByGender[0].data[8] -= x[`p6_m${year}`] ? x[`p6_m${year}`] : 0

      result.studentLevelEduByGender[0].data[9] -= x[`m1_m${year}`] ? x[`m1_m${year}`] : 0
      result.studentLevelEduByGender[0].data[10] -= x[`m2_m${year}`] ? x[`m2_m${year}`] : 0
      result.studentLevelEduByGender[0].data[11] -= x[`m3_m${year}`] ? x[`m3_m${year}`] : 0
      result.studentLevelEduByGender[0].data[12] -= x[`m4_m${year}`] ? x[`m4_m${year}`] : 0
      result.studentLevelEduByGender[0].data[13] -= x[`m5_m${year}`] ? x[`m5_m${year}`] : 0
      result.studentLevelEduByGender[0].data[14] -= x[`m6_m${year}`] ? x[`m6_m${year}`] : 0
      result.studentLevelEduByGender[0].data[15] -= x[`pvc_m${year}`] ? x[`pvc_m${year}`] : 0
      result.studentLevelEduByGender[0].data[16] -= x[`university_m${year}`] ? x[`university_m${year}`] : 0
      result.studentLevelEduByGender[0].data[17] -= x[`university_m${year}`] ? x[`university_m${year}`] : 0

      // Wowen
      result.studentLevelEduByGender[1].data[0] += x[`k1_f${year}`] ? x[`k1_f${year}`] : 0
      result.studentLevelEduByGender[1].data[1] += x[`k2_f${year}`] ? x[`k2_f${year}`] : 0
      result.studentLevelEduByGender[1].data[2] += x[`k3_f${year}`] ? x[`k3_f${year}`] : 0

      result.studentLevelEduByGender[1].data[3] += x[`p1_f${year}`] ? x[`p1_f${year}`] : 0
      result.studentLevelEduByGender[1].data[4] += x[`p2_f${year}`] ? x[`p2_f${year}`] : 0
      result.studentLevelEduByGender[1].data[5] += x[`p3_f${year}`] ? x[`p3_f${year}`] : 0
      result.studentLevelEduByGender[1].data[6] += x[`p4_f${year}`] ? x[`p4_f${year}`] : 0
      result.studentLevelEduByGender[1].data[7] += x[`p5_f${year}`] ? x[`p5_f${year}`] : 0
      result.studentLevelEduByGender[1].data[8] += x[`p6_f${year}`] ? x[`p6_f${year}`] : 0

      result.studentLevelEduByGender[1].data[9] += x[`m1_f${year}`] ? x[`m1_f${year}`] : 0
      result.studentLevelEduByGender[1].data[10] += x[`m2_f${year}`] ? x[`m2_f${year}`] : 0
      result.studentLevelEduByGender[1].data[11] += x[`m3_f${year}`] ? x[`m3_f${year}`] : 0
      result.studentLevelEduByGender[1].data[12] += x[`m4_f${year}`] ? x[`m4_f${year}`] : 0
      result.studentLevelEduByGender[1].data[13] += x[`m5_f${year}`] ? x[`m5_f${year}`] : 0
      result.studentLevelEduByGender[1].data[14] += x[`m6_f${year}`] ? x[`m6_f${year}`] : 0
      result.studentLevelEduByGender[1].data[15] += x[`pvc_f${year}`] ? x[`pvc_f${year}`] : 0
      result.studentLevelEduByGender[1].data[16] += x[`university_f${year}`] ? x[`university_f${year}`] : 0
      result.studentLevelEduByGender[1].data[17] += x[`university_f${year}`] ? x[`university_f${year}`] : 0
    })

  console.log("data _filterData: ", result)

  return (result)
}

const mapDataTable = (typessssd, data, year = 'all') => {
  const SUFFIX_YEAR = {
    '1/2561': "2561",
    '2/2561': "2561",
    '1/2562': "2562",
    '2/2562': "2562",
    '1/2563': "2563",
    '2/2563': "2563",
    '1/2564': "2564",
    '2/2564': "2564",
    '1/2565': "2565",
    '2/2565': "2565",
    '1/2566': "2566",
    '2/2566': "2566",
    '1/2567': "2567",
    "2561": "2561",
    "2562": "2562",
    "2563": "2563",
    "2564": "2564",
    "2565": "2565",
    "2566": "2566",
    "2567": "2567",
  }
  console.log("typessssd", typessssd)
  console.log("datadatadatadatadata", data)
  let map = null
  if (typessssd === 'table1') {
    return (data.map(_data => {
      return ({
        studentMen: _data[`studentMen${SUFFIX_YEAR[year]}`],
        studentWomen: _data[`studentWomen${SUFFIX_YEAR[year]}`],
        studens: _data[`studentMen${SUFFIX_YEAR[year]}`] + _data[`studentWomen${SUFFIX_YEAR[year]}`],
        province_name: _data.province_name,
        city_name: _data.city_name,
        tumbon_name: _data.tumbon_name,
        sungkud: _data.sungkud,
        area: _data.area,
      })
    }))
  } else if (typessssd === 'table2') {
    return (data
      .filter(_data => _data.region_thai === year)
      .map(_data => {
        let student_total_61 = _data[`studentMen2561`] + _data[`studentWomen2561`]
        let student_total_62 = _data[`studentMen2562`] + _data[`studentWomen2562`]
        let student_total_63 = _data[`studentMen2563`] + _data[`studentWomen2563`]
        let student_total_64 = _data[`studentMen2564`] + _data[`studentWomen2564`]
        let student_total_65 = _data[`studentMen2565`] + _data[`studentWomen2565`]
        let student_total_66 = _data[`studentMen2566`] + _data[`studentWomen2566`]
        let student_total_67 = _data[`studentMen2567`] + _data[`studentWomen2567`]
        return ({
          student_total_61: student_total_61,
          student_total_62: student_total_62,
          student_total_63: student_total_63,
          student_total_64: student_total_64,
          student_total_65: student_total_65,
          student_total_66: student_total_66,
          student_total_67: student_total_67,
          province_name: _data.province_name,
          city_name: _data.city_name,
          tumbon_name: _data.tumbon_name,
          sungkud: _data.sungkud,
          area: _data.area,
        })
      }))
  } else if (typessssd === 'table3') {
    return (data.map(_data => {
      return ({
        studentMen2561: _data[`studentMen2561`],
        studentWomen2561: _data[`studentWomen2561`],
        studentTotal2561: _data[`studentMen2561`] + _data[`studentWomen2561`],

        studentMen2562: _data[`studentMen2562`],
        studentWomen2562: _data[`studentWomen2562`],
        studentTotal2562: _data[`studentMen2562`] + _data[`studentWomen2562`],

        studentMen2563: _data[`studentMen2563`],
        studentWomen2563: _data[`studentWomen2563`],
        studentTotal2563: _data[`studentMen2563`] + _data[`studentWomen2563`],

        studentMen2564: _data[`studentMen2564`],
        studentWomen2564: _data[`studentWomen2564`],
        studentTotal2564: _data[`studentMen2564`] + _data[`studentWomen2564`],

        studentMen2565: _data[`studentMen2565`],
        studentWomen2565: _data[`studentWomen2565`],
        studentTotal2565: _data[`studentMen2565`] + _data[`studentWomen2565`],

        studentMen2566: _data[`studentMen2566`],
        studentWomen2566: _data[`studentWomen2566`],
        studentTotal2566: _data[`studentMen2566`] + _data[`studentWomen2566`],

        studentMen2567: _data[`studentMen2567`],
        studentWomen2567: _data[`studentWomen2567`],
        studentTotal2567: _data[`studentMen2567`] + _data[`studentWomen2567`],

        province_name: _data.province_name,
        city_name: _data.city_name,
        tumbon_name: _data.tumbon_name,
        sungkud: _data.sungkud,
        area: _data.area,
      })
    }))
  } else if (typessssd === 'table4') {
    return data
      .map(_data => {
        let k_men = checkValue(_data[`k1_m${year}`]) + checkValue(_data[`k2_m${year}`]) + checkValue(_data[`k3_m${year}`])
        let k_female = checkValue(_data[`k1_f${year}`]) + checkValue(_data[`k2_f${year}`]) + checkValue(_data[`k3_f${year}`])

        let p_men = checkValue(_data[`p1_m${year}`]) + checkValue(_data[`p2_m${year}`]) + checkValue(_data[`p3_m${year}`])
          + checkValue(_data[`p4_m${year}`]) + checkValue(_data[`p5_m${year}`]) + checkValue(_data[`p6_m${year}`])
        let p_female = checkValue(_data[`p1_f${year}`]) + checkValue(_data[`p2_f${year}`]) + checkValue(_data[`p3_f${year}`])
          + checkValue(_data[`p4_f${year}`]) + checkValue(_data[`p5_f${year}`]) + checkValue(_data[`p6_f${year}`])

        let mt_men = checkValue(_data[`m1_m${year}`]) + checkValue(_data[`m2_m${year}`]) + checkValue(_data[`m3_m${year}`])
        let mt_female = checkValue(_data[`m1_f${year}`]) + checkValue(_data[`m2_f${year}`]) + checkValue(_data[`m3_f${year}`])

        let hi_men = checkValue(_data[`m4_m${year}`]) + checkValue(_data[`m5_m${year}`]) + checkValue(_data[`m6_m${year}`])
        let hi_female = checkValue(_data[`m4_f${year}`]) + checkValue(_data[`m5_f${year}`]) + checkValue(_data[`m6_f${year}`])

        let student_total = k_men + k_female + p_men + p_female + mt_men + mt_female + hi_men + hi_female
        return ({
          student_total: student_total,
          k_men: k_men,
          k_female: k_female,
          p_men: p_men,
          p_female: p_female,
          mt_men: mt_men,
          mt_female: mt_female,
          hi_men: hi_men,
          hi_female: hi_female,
          province_name: _data.province_name,
          city_name: _data.city_name,
          tumbon_name: _data.tumbon_name,
          sungkud: _data.sungkud,
          area: _data.area,
        })
      })
  } else if (typessssd === 'table5') {
    return data
      .map(_data => {
        let k = checkValue(_data[`k1_m${year}`]) + checkValue(_data[`k2_m${year}`]) + checkValue(_data[`k3_m${year}`])
          + checkValue(_data[`k1_f${year}`]) + checkValue(_data[`k2_f${year}`]) + checkValue(_data[`k3_f${year}`])

        let p = checkValue(_data[`p1_m${year}`]) + checkValue(_data[`p2_m${year}`]) + checkValue(_data[`p3_m${year}`])
          + checkValue(_data[`p4_m${year}`]) + checkValue(_data[`p5_m${year}`]) + checkValue(_data[`p6_m${year}`])
          + checkValue(_data[`p1_f${year}`]) + checkValue(_data[`p2_f${year}`]) + checkValue(_data[`p3_f${year}`])
          + checkValue(_data[`p4_f${year}`]) + checkValue(_data[`p5_f${year}`]) + checkValue(_data[`p6_f${year}`])

        let mt = checkValue(_data[`m1_m${year}`]) + checkValue(_data[`m2_m${year}`]) + checkValue(_data[`m3_m${year}`])
          + checkValue(_data[`m1_f${year}`]) + checkValue(_data[`m2_f${year}`]) + checkValue(_data[`m3_f${year}`])

        let hi = checkValue(_data[`m4_f${year}`]) + checkValue(_data[`m5_f${year}`]) + checkValue(_data[`m6_f${year}`]) + checkValue(_data[`m4_m${year}`]) + checkValue(_data[`m5_m${year}`]) + checkValue(_data[`m6_m${year}`])
        let student_total = k + p + mt + hi

        return ({
          student_total: student_total,
          k: k,
          p: p,
          mt: mt,
          hi: hi,
          province_name: _data.province_name,
          city_name: _data.city_name,
          tumbon_name: _data.tumbon_name,
          sungkud: _data.sungkud,
          area: _data.area,
        })
      })
  }
}

export default function StudentStatisticsPage(props) {
  let [dataSummaryAllyear, setDataSummaryAllyear] = React.useState([])
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [columns, setColumns] = React.useState([]);
  const [dataTable, setDataTable] = React.useState([]);
  const [title, setTitle] = React.useState('');

  const [isModalQinfoVisible, setIsModalQinfoVisible] = React.useState(false);
  const [qinfo, setQinfo] = React.useState({
    description: '',
    refs: []
  });

  const [filter, setFilter] = React.useState({
    type: 'all',
    view: 'province',
    year: '2567',
    // data: props.pageContext.data,
    data: [],
    province_name: null,
    city_name: null,
    tumbon_name: null,
    sungkud: null,
    area: null,
  })

  const [school, setSchool] = React.useState({
    studentJonAndExjon: [],
    students_total: 0,
    students_women: 0,
    students_men: 0,
  });


  React.useEffect(async () => {
    let res = await api.getInitData('student-statistics-province')
    console.log(res.data)
    setDataSummaryAllyear(res.data)
  }, [])

  React.useEffect(() => {
    setSchool(mapData(dataSummaryAllyear, filter))
  }, [filter, dataSummaryAllyear]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  console.log("dataSummaryAllyear: ", dataSummaryAllyear)
  console.log("filter: ", filter)

  return (
    <Layout Role={['eef']}>
      {
        filter.view === 'sungkud' ?
          <ModalTabelBySungkud
            title={title}
            isModalVisible={isModalVisible}
            dataSource={dataTable}
            columns={columns}
            handleOk={handleOk}
            handleCancel={handleCancel}
            rowSpan={2}
          />
          :
          <ModalTabelByProvicce
            title={title}
            isModalVisible={isModalVisible}
            dataSource={dataTable}
            columns={columns}
            handleOk={handleOk}
            handleCancel={handleCancel}
            rowSpan={2}
          />
      }
      < ModalQinfo
        refs={qinfo.refs}
        description={qinfo.description}
        isModalVisible={isModalQinfoVisible}
        handleCancel={() => {
          setIsModalQinfoVisible(false)
          setQinfo({
            description: '',
            refs: []
          });
        }}
        handleOk={() => {
          setIsModalQinfoVisible(false)
          setQinfo({
            description: '',
            refs: []
          });
        }}
      />
      <div style={{
        width: '100%',
        justifyContent: 'center',
        display: 'flex',
        backgroundImage: 'url(' + bg + ')',
        backgroundSize: 'contain'
      }}>
        <div style={{
          width: '1200px',
          backdropFilter: 'blur(2px)',
          backgroundColor: 'rgba(255, 255, 255, 0.6)',
          paddingTop: 60,
          marginBottom: 200
        }}>

          <Banner
            title={'ประชากรวัยเรียน'}
            description={`ข้อมูลประชากรวัยเรียนนั้นจะเป็นข้อมูลที่แสดงสถิติจำนวนเด็กและเยาวชนที่อยู่ในระบบการศึกษา ซึ่งเป็นการบูรณาการร่วมกับหน่วยงานต้นสังกัด โดยการนำเสนอในส่วนนี้จะครอบคลุมทั้งในระดับการศึกษาขั้นพื้นฐาน และในระดับที่สูงกว่าการศึกษาขั้นพื้นฐาน `}
            image={image_test01} />


          <Row
            sx={{ height: 80 }}
            md={{ height: null }}
          >
            <Col
              xs={{ span: 24 }}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div className="code-box-shape4 queue-anim-demo" style={{ ...styleCard, flexDirection: 'row' }} >
                <Filter
                  optionYear={[
                    { value: '2567', text: '2567' },
                    { value: '2566', text: '2566' },
                    { value: '2565', text: '2565' },
                    { value: '2564', text: '2564' },
                    { value: '2563', text: '2563' },
                  ]}
                  optionSungkud={[
                    { value: null, text: 'ทั้งหมด' },
                    { value: 'สำนักงานคณะกรรมการการศึกษาขั้นพื้นฐาน', text: 'สำนักงานคณะกรรมการการศึกษาขั้นพื้นฐาน (สพฐ.)' },
                    { value: 'กรมส่งเสริมการปกครองท้องถิ่น', text: 'กรมส่งเสริมการปกครองท้องถิ่น (อปท.)' },
                    { value: 'กองบัญชาการตำรวจตระเวนชายแดน', text: 'กองบัญชาการตำรวจตระเวนชายแดน (ตชด.)' },
                    { value: 'สำนักงานพระพุทธศาสนาแห่งชาติ', text: 'สำนักงานพระพุทธศาสนาแห่งชาติ (พศ.)' },
                    { value: 'สำนักงานคณะกรรมการส่งเสริมการศึกษาเอกชน', text: 'สำนักงานคณะกรรมการส่งเสริมการศึกษาเอกชน (สช.)' },
                    { value: 'สำนักการศึกษา กรุงเทพมหานคร', text: 'สำนักการศึกษา กรุงเทพมหานคร' },
                    { value: 'สำนักการศึกษา เมืองพัทยา', text: 'สำนักการศึกษา เมืองพัทยา' },
                    { value: 'มหาวิทยาลัยการกีฬาแห่งชาติ', text: 'มหาวิทยาลัยการกีฬาแห่งชาติ' },
                    { value: 'สถาบันบัณฑิตพัฒนศิลป์', text: 'สถาบันบัณฑิตพัฒนศิลป์' },
                    { value: 'สำนักงานคณะกรรมการการอาชีวศึกษา', text: 'สำนักงานคณะกรรมการการอาชีวศึกษา' },
                    { value: 'กระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัยและนวัตกรรม', text: 'กระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัยและนวัตกรรม' },
                    { value: 'สำนักงานส่งเสริมการศึกษานอกระบบและการศึกษาตามอัธยาศัย', text: 'สำนักงานส่งเสริมการศึกษานอกระบบและการศึกษาตามอัธยาศัย' },
                    { value: 'โรงเรียนมหิดลวิทยานุสรณ์', text: 'โรงเรียนมหิดลวิทยานุสรณ์' },
                  ]}
                  data={filter}
                  onChange={({
                    year,
                    province,
                    district,
                    subDistrict,
                    type,
                    area,
                    sungkud,
                    view
                  }) => {

                    if (filter.view !== view) {
                      if (view === "sungkud") {
                        navigate(`/student-statistics/sungkud`)
                      } else {
                        navigate(`/student-statistics/country`)
                      }
                    } else {
                      setFilter(
                        {
                          ...filter,
                          year: year,
                          tumbon_name: subDistrict,
                          city_name: district,
                          province_name: province,
                          area,
                          sungkud,
                          type: type,
                          view: view,
                        })
                    }
                  }}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={{ span: 24 }} sm={{ span: 8 }} style={{ display: 'flex', flexDirection: 'column', }}>
              <div className="code-box-shape4 queue-anim-demo" style={{ ...styleCard, height: '130px', flexDirection: 'row', justifyContent: 'space-between' }} >
                <img src={students} alt="Logoeef" width={'72'} height={'72'} style={{ alignSelf: 'center' }} />
                <div>
                  <div style={{ fontSize: '2.2rem', color: '#333', fontFamily: 'Kanit', textAlign: 'right' }}>  นักเรียนทั้งหมด</div>
                  <div style={{ fontSize: '2.2rem', color: '#39A388', fontFamily: 'Kanit', textAlign: 'right' }}> {`${school?.students_total?.toLocaleString('en-US')} คน`}</div>
                </div>
              </div>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 8 }} style={{ display: 'flex', flexDirection: 'column', }}>
              <div className="code-box-shape4 queue-anim-demo" style={{ ...styleCard, height: '130px', flexDirection: 'row', justifyContent: 'space-between' }} >
                <img src={student_woman} alt="Logoeef" width={'72'} height={'72'} style={{ alignSelf: 'center' }} />
                <div>
                  <div style={{ fontSize: '2.2rem', color: '#333', fontFamily: 'Kanit', textAlign: 'right' }}>  เพศหญิง</div>
                  <div style={{ fontSize: '2.2rem', color: '#FFADF0', fontFamily: 'Kanit', textAlign: 'right' }}> {`${school?.students_women?.toLocaleString('en-US')} คน`}</div>
                </div>
              </div>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 8 }} style={{ display: 'flex', flexDirection: 'column', }}>
              <div className="code-box-shape4 queue-anim-demo" style={{ ...styleCard, height: '130px', flexDirection: 'row', justifyContent: 'space-between' }} >
                <img src={student_man} alt="Logoeef" width={'72'} height={'72'} style={{ alignSelf: 'center' }} />
                <div>
                  <div style={{ fontSize: '2.2rem', color: '#333', fontFamily: 'Kanit', textAlign: 'right' }}>  เพศชาย</div>
                  <div style={{ fontSize: '2.2rem', color: '#5584AC', fontFamily: 'Kanit', textAlign: 'right' }}> {`${school?.students_men?.toLocaleString('en-US')} คน`}</div>
                </div>
              </div>
            </Col>
          </Row>

          <Row>

            <Col xs={{ span: 24 }} sm={{ span: 12 }} style={{ display: 'flex', flexDirection: 'column', }}>
              <div className="code-box-shape4 queue-anim-demo" style={{ ...styleCard, height: '360px' }} >
                <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                  <div className='w-0 md:w-[72px]'></div>
                  <div>
                    <Title>สถิติจำนวนนักเรียนในประเทศไทย</Title>
                    <Title fontSize={"0.8rem"}>ปีการศึกษา 2563 - 2567 </Title>
                  </div>
                  <div style={{ width: '72px', justifyContent: 'space-around', flexDirection: 'row', display: 'flex' }} >
                    <div style={{ width: '24px', justifyContent: 'space-around' }} >
                      <TableOutlined style={{ fontSize: '24px', color: '#374151' }} onClick={() => {
                        showModal()
                        setColumns(confixColumnsTable.studentSchool)
                        setDataTable(mapDataTable('table1', dataSummaryAllyear, filter.year))
                        setTitle('จำนวนนักเรียนทั้งหมดในประเทศไทย ปีการศึกษา ' + filter.year)
                      }} />
                    </div>
                    <div style={{ width: '24px', justifyContent: 'space-around' }} >
                      <InfoCircleOutlined style={{ fontSize: '24px', color: '#374151' }}
                        onClick={() => {
                          setQinfo({
                            description: `จำนวนนักเรียนทั้งหมดที่ศึกษาในประเทศไทยตั้งแต่ปีการศึกษา 2563 - 2567 (ข้อมูลปี 2563 - 2567 เป็นข้อมูลของสังกัด สพฐ, อปท, ตชด, สช, กทม, และสำนักพุทธศาสนา)`,
                            refs: [
                              // "จำนวนนักเรียนทั้งหมดปีการศึกษา 2559 - 2561 เป็นข้อมูลของสังกัด สพฐ",
                              "จำนวนนักเรียนทั้งหมดปีการศึกษา 2563 - 2567 เป็นข้อมูลของสังกัด สพฐ, อปท, ตชด, สช, กทม, และสำนักพุทธศาสนา"
                            ]
                          });
                          setIsModalQinfoVisible(true)
                        }}
                      />
                    </div>
                  </div>
                </div>
                <Chart
                  options={{
                    title: '',
                    type: 'column',
                    categories: ['2563', '2564', '2565', '2566', '2567'],
                    yAxis: {
                      title: {
                        text: 'จำนวนนักเรียน (คน)'
                      }
                    }
                  }}
                  click={({ e, category, name }) => {
                    showModal()
                    setColumns(confixColumnsTable.studentSchool)
                    setDataTable(mapDataTable('table1', dataSummaryAllyear, category))
                    setTitle('จำนวนนักเรียนทั้งหมดในประเทศไทย ปีการศึกษา ' + category)
                  }}
                  containerProps={{ style: { height: "260px" } }}
                  style={{ width: '100px', height: '320px' }}
                  data={school.studentStatistics}
                />
              </div>

            </Col>


            <Col xs={{ span: 24 }} sm={{ span: 12 }} style={{ display: 'flex', flexDirection: 'column', }}>
              <div className="code-box-shape4 queue-anim-demo" style={{ ...styleCard, height: '360px' }} >
                <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                  <div className='w-0 md:w-[72px]'></div>
                  <div>
                    <Title>จำนวนนักเรียนในประเทศไทยแบ่งตามภาค</Title>
                    <Title fontSize={"0.8rem"}>ปีการศึกษา 2563 – 2567</Title>
                  </div>
                  <div style={{ width: '72px', justifyContent: 'space-around', flexDirection: 'row', display: 'flex' }} >
                    <div style={{ width: '24px', justifyContent: 'space-around' }} >
                      <TableOutlined style={{ fontSize: '24px', color: '#374151' }} onClick={() => {
                        showModal()
                        setColumns(confixColumnsTable.studentStatisticsRegin)
                        setDataTable(mapDataTable('table2', dataSummaryAllyear, 'กลาง'))
                        setTitle('จำนวนนักเรียนทั้งหมดในประเทศไทย ภาคกลาง')
                      }} />
                    </div>
                    <div style={{ width: '24px', justifyContent: 'space-around' }} >
                      <InfoCircleOutlined style={{ fontSize: '24px', color: '#374151' }}
                        onClick={() => {
                          setQinfo({
                            description: `จำนวนนักเรียนทั้งหมดที่ศึกษาในประเทศไทยตั้งแต่ปีการศึกษา 2563 - 2567 (ข้อมูลปี 2563 - 2567 เป็นข้อมูลของสังกัด สพฐ, อปท, ตชด, สช, กทม, และสำนักพุทธศาสนา)`,
                            refs: [
                              // "จำนวนนักเรียนทั้งหมดปีการศึกษา 2559 - 2561 เป็นข้อมูลของสังกัด สพฐ",
                              "จำนวนนักเรียนทั้งหมดปีการศึกษา 2563 - 2567 เป็นข้อมูลของสังกัด สพฐ, อปท, ตชด, สช, กทม, และสำนักพุทธศาสนา"
                            ]
                          });
                          setIsModalQinfoVisible(true)
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div style={{ marginTop: 28 }}>
                  <Chart
                    containerProps={{ style: { height: "260px" } }}
                    style={{ width: '100px', height: '260px' }}
                    options={{
                      title: '',
                      type: 'column',
                      categories: ['2563', '2564', '2565', '2566', '2567'],
                      yAxis: {
                        title: {
                          text: 'จำนวนนักเรียน (คน)'
                        }
                      }
                    }}
                    click={({ e, category, name }) => {
                      showModal()
                      setColumns(confixColumnsTable.studentStatisticsRegin)
                      setDataTable(mapDataTable('table2', dataSummaryAllyear, name))
                      setTitle('จำนวนนักเรียนทั้งหมดในประเทศไทย ภาค' + name)
                    }}
                    data={school.studentByRegin}
                  />
                </div>
              </div>
            </Col>
          </Row>


          <Row>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} style={{ display: 'flex', flexDirection: 'column', }}>
              <div className="code-box-shape4 queue-anim-demo" style={{ ...styleCard, height: '360px' }} >
                <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                  <div className='w-0 md:w-[72px]'></div>
                  <div>
                    <Title>จำนวนนักเรียนแบ่งตามเพศ</Title>
                    <Title fontSize={"0.8rem"}>ปีการศึกษา 2563 – 2567</Title>
                  </div>
                  <div style={{ width: '72px', justifyContent: 'space-around', flexDirection: 'row', display: 'flex' }} >
                    <div style={{ width: '24px', justifyContent: 'space-around' }} >
                      <TableOutlined style={{ fontSize: '24px', color: '#374151' }} onClick={() => {
                        showModal()
                        setColumns(confixColumnsTable.studentStatisticsGender)
                        setDataTable(mapDataTable('table3', dataSummaryAllyear))
                        setTitle('จำนวนนักเรียนแบ่งตามเพศ ปีการศึกษา2563 – 2567')
                      }} />
                    </div>
                    <div style={{ width: '24px', justifyContent: 'space-around' }} >
                      <InfoCircleOutlined style={{ fontSize: '24px', color: '#374151' }}
                        onClick={() => {
                          setQinfo({
                            description: ` ข้อมูลจำนวนนักเรียนทั้งหมดซึ่งศึกษาอยู่ภายในประเทศไทย ในปีการศึกษา 2563 – 2567 ทั้งเพศชายและเพศหญิง`,
                            refs: [
                              // "จำนวนนักเรียนทั้งหมดปีการศึกษา 2559 - 2561 เป็นข้อมูลของสังกัด สพฐ",
                              "จำนวนนักเรียนทั้งหมดปีการศึกษา 2563 - 2567 เป็นข้อมูลของสังกัด สพฐ, อปท, ตชด, สช, กทม, และสำนักพุทธศาสนา"
                            ]
                          });
                          setIsModalQinfoVisible(true)
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div style={{ marginTop: 28 }}>
                  <Chart
                    containerProps={{ style: { height: "260px" } }}
                    style={{ width: '100px', height: '260px' }}
                    options={{
                      title: '',
                      type: 'line',
                      categories: ['2563', '2564', '2565', '2566', '2567'],
                      yAxis: {
                        title: {
                          text: 'จำนวนนักเรียน (คน)'
                        }
                      }
                    }}
                    click={({ e, category, name }) => {
                      showModal()
                      setColumns(confixColumnsTable.studentStatisticsGender)
                      setDataTable(mapDataTable('table3', dataSummaryAllyear))
                      setTitle('จำนวนนักเรียนแบ่งตามเพศ ปีการศึกษา2563 – 2567')
                    }}
                    data={school.studentGender}
                  />
                </div>
              </div>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} style={{ display: 'flex', flexDirection: 'column', }}>
              <div className="code-box-shape4 queue-anim-demo" style={{ ...styleCard, height: '360px' }} >
                <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                  <div className='w-0 md:w-[72px]'></div>
                  <div>
                    <Title>จำนวนนักเรียนแบ่งตามเพศและระดับชั้น</Title>
                    <Title fontSize={"0.8rem"} > ปีการศึกษา {filter.year}</Title>
                  </div>
                  <div style={{ width: '72px', justifyContent: 'space-around', flexDirection: 'row', display: 'flex' }} >
                    <div style={{ width: '24px', justifyContent: 'space-around' }} >
                      <TableOutlined style={{ fontSize: '24px', color: '#374151' }} onClick={() => {
                        showModal()
                        setColumns(confixColumnsTable.studentStatisticsClassAndGender)
                        setDataTable(mapDataTable('table4', dataSummaryAllyear, filter.year))
                        setTitle('จำนวนนักเรียน จำแนกตามเพศและช่วงชั้น ปีการศึกษา' + filter.year)
                      }} />
                    </div>
                    <div style={{ width: '24px', justifyContent: 'space-around' }} >
                      <InfoCircleOutlined style={{ fontSize: '24px', color: '#374151' }}
                        onClick={() => {
                          setQinfo({
                            description: `ข้อมูลจำนวนนักเรียนทั้งหมดซึ่งศึกษาอยู่ในประเทศไทย ในปีการศึกษา 2563 – 2567 ไม่ว่าจะเป็นระดับชั้นอนุบาล ประถมศึกษา มัธยมต้น และมัธยมปลาย ซึ่งในแต่ละระดับชั้นนั้น นักเรียนจะถูกจำแนกตามเพศ ทั้งเพศหญิงและเพศชาย`,
                            refs: [
                              "จำนวนนักเรียนทั้งหมดปีการศึกษา 2563 - 2567 เป็นข้อมูลของสังกัด สพฐ, อปท, ตชด, สช, กทม, และสำนักพุทธศาสนา"
                            ]
                          });
                          setIsModalQinfoVisible(true)
                        }}
                      />
                    </div>
                  </div>
                </div>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={
                    {
                      chart: {
                        type: 'bar',
                        style: {
                          fontFamily: 'Kanit'
                        }
                      },
                      title: {
                        text: null
                      },
                      accessibility: {
                        point: {
                          valueDescriptionFormat: '{index}. Age {xDescription}, {value}%.'
                        }
                      },
                      xAxis: [{
                        categories: categories,
                        reversed: false,
                        labels: {
                          step: 1
                        },
                        accessibility: {
                          description: 'Age (male)'
                        }
                      },
                      { // mirror axis on right side
                        opposite: true,
                        reversed: false,
                        categories: categories,
                        linkedTo: 0,
                        labels: {
                          step: 1
                        },
                        accessibility: {
                          description: 'Age (female)'
                        }
                      }],
                      yAxis: {
                        title: {
                          text: null
                        },
                        labels: {
                          formatter: function () {
                            let value = Math.abs(this.value)
                            if (value >= 10e5) {
                              return Highcharts.numberFormat(value / 10e5, 1) + " ล้าน"
                            } else if (value >= 10e4) {
                              return Highcharts.numberFormat(value / 10e4, 1) + " แสน"
                            } else if (value >= 10e3) {
                              return Highcharts.numberFormat(value / 10e3, 1) + " หมื่น"
                            } else if (value >= 10e2) {
                              return Highcharts.numberFormat(value / 10e2, 1) + " พัน"
                            } else {
                              return value
                            }
                          }

                          // function() {
                          //   return Math.abs(this.value);
                          // }
                        },
                        accessibility: {
                          description: 'Percentage population',
                          rangeDescription: 'Range: 0 to 5%'
                        }
                      },

                      plotOptions: {
                        series: {
                          stacking: 'normal',
                          point: {
                            events: {
                              click: function (e) {
                                showModal()
                                setColumns(confixColumnsTable.studentStatisticsClassAndGender)
                                setDataTable(mapDataTable('table4', dataSummaryAllyear, filter.year))
                                setTitle('จำนวนนักเรียน จำแนกตามเพศและช่วงชั้น ปีการศึกษา' + filter.year)
                              }
                            }
                          }
                        }
                      },

                      tooltip: {
                        formatter: function () {
                          return '<b>' + this.series.name + " " + this.point.category + '</b><br/>' +
                            ': ' + Highcharts.numberFormat(Math.abs(this.point.y), 0) + ' คน';
                        }
                      },
                      series: school.studentLevelEduByGender
                    }
                  }
                  containerProps={{ style: { height: "320px" } }}
                  style={{ width: '100px', height: '320px' }}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={{ span: 24 }} style={{ display: 'flex', flexDirection: 'column', }}>
              <div className="code-box-shape4 queue-anim-demo" style={{ ...styleCard, height: '380px' }} >
                <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                  <div className='w-0 md:w-[72px]'></div>
                  <div>
                    <Title>จำนวนนักเรียนแบ่งตามระดับชั้น</Title>
                    <Title fontSize={"0.8rem"}>ปีการศึกษา 2563 - 2567</Title>
                  </div>
                  <div style={{ width: '72px', justifyContent: 'space-around', flexDirection: 'row', display: 'flex' }} >
                    <div style={{ width: '24px', justifyContent: 'space-around' }} >
                      <TableOutlined style={{ fontSize: '24px', color: '#374151' }} onClick={() => {
                        showModal()
                        setColumns(confixColumnsTable.studentStatisticsClass)
                        setDataTable(mapDataTable('table5', dataSummaryAllyear, filter.year))
                        setTitle('จำนวนนักเรียนทั้งหมดแบ่งตามระดับชั้น ปีการศึกษา' + filter.year)
                      }} />
                    </div>
                    <div style={{ width: '24px', justifyContent: 'space-around' }} >
                      <InfoCircleOutlined style={{ fontSize: '24px', color: '#374151' }}
                        onClick={() => {
                          // setQinfo({
                          //   description: ` ข้อมูลจำนวนนักเรียนทั้งหมดซึ่งศึกษาอยู่ในประเทศไทย ในปีการศึกษา 2559 – 2564 ไม่ว่าจะเป็นระดับชั้นอนุบาล ประถมศึกษา มัธยมต้น และมัธยมปลาย ซึ่งในแต่ละระดับชั้นนั้น นักเรียนจะถูกจำแนกตามเพศ ทั้งเพศหญิงและเพศชาย`,
                          //   refs: [
                          //     "จำนวนนักเรียนทั้งหมดปีการศึกษา 2559 - 2561 เป็นข้อมูลของสังกัด สพฐ",
                          //     "จำนวนนักเรียนทั้งหมดปีการศึกษา 2562 - 2564 เป็นข้อมูลของสังกัด สพฐ, อปท, ตชด, สช, กทม, และสำนักพุทธศาสนา"
                          //   ]
                          // });
                          setQinfo({
                            description: `ข้อมูลจำนวนนักเรียนทั้งหมดซึ่งศึกษาอยู่ในประเทศไทย ในปีการศึกษา 2563 – 2567 ไม่ว่าจะเป็นระดับชั้นอนุบาล ประถมศึกษา มัธยมต้น และมัธยมปลาย ซึ่งในแต่ละระดับชั้นนั้น นักเรียนจะถูกจำแนกตามเพศ ทั้งเพศหญิงและเพศชาย`,
                            refs: [
                              "จำนวนนักเรียนทั้งหมดปีการศึกษา 2563 - 2567 เป็นข้อมูลของสังกัด สพฐ, อปท, ตชด, สช, กทม, และสำนักพุทธศาสนา"
                            ]
                          });
                          setIsModalQinfoVisible(true)
                        }}
                      />
                    </div>
                  </div>
                </div>

                <Chart
                  options={{
                    title: '',
                    type: 'column',
                    yAxis: {
                      title: {
                        text: 'จำนวนนักเรียน (คน)'
                      }
                    },
                    categories: ['2563', '2564', '2565', '2566', '2567']
                  }}
                  containerProps={{ style: { height: "330px" } }}
                  style={{ width: '100px', height: '320px' }}
                  click={({ e, category, name }) => {
                    showModal()
                    setColumns(confixColumnsTable.studentStatisticsClass)
                    setDataTable(mapDataTable('table5', dataSummaryAllyear, category))
                    setTitle('จำนวนนักเรียนทั้งหมดแบ่งตามระดับชั้น ปีการศึกษา' + category)
                  }}
                  data={school.studentLevelEdu}

                />
              </div>
            </Col>

          </Row>
          <RefDataDate
            title=""
            subjectTitle="*ประมวลผล ณ เดือนสิงหาคม 2567"
          />
        </div>
      </div >
    </Layout >
  );
}

